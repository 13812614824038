import "./App.css";
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {NextUIProvider} from "@nextui-org/react";

import Login from "./components/auth/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import ClubListInUserDistrict from "./components/admin/clubs/ClubListInUserDistrict";
import Header from "./components/admin/home/Header";
import Ambassadorial from "./components/admin/Ambassadorial/Ambassadorial";
import Meetings from "./components/admin/Meetings/Meetings";
import Finances from "./components/admin/Finances/Finances";
import Membership from "./components/admin/Membership/Membership";
import Projects from "./components/admin/projects/Projects";
import EdgeCard from "./components/admin/PriviledgeCards/EdgeCard";
import ViewNominations from "./components/admin/aaras/ViewNominations";
import NewNominationForProjects from "./components/admin/aaras/NewNominationForProjects";
import ViewNominationsForDistrictAdmin from "./components/admin/aaras/ViewNominationsForDistrictAdmin";
import Questionnaire from "./components/council57/Questionnaire";
import ThankYouPage from "./components/council57/ThankYouPage";
import ApplicationResponses from "./components/council57/ApplicationResponses";
import IndividualApplications from "./components/council57/IndividualApplications";
import EditMemberData from "./components/admin/Membership/EditMemberData";
import MembershipStepper from "./components/admin/Membership/AddNew/MembershipStepper";
import ReportAmbassadorial from "./components/admin/Ambassadorial/ReportAmbassadorial";
import ViewReportAmbassadorial from "./components/admin/Ambassadorial/ViewReportAmbassadorial";
import ReportMeetings from "./components/admin/Meetings/ReportMeetings";
import ViewReportMeetingMinutes from "./components/admin/Meetings/ViewReportMeetingMinutes";
import ViewReportProject from "./components/admin/projects/ViewReportProject";
import NewNominationForIndividualAwards from "./components/admin/aaras/NewNominationForIndividualAwards";
import ReportProject from "./components/admin/projects/ReportProject";
import OnBoardNewClubAndOfficers from "./components/admin/clubs/OnBoardNewClubAndOfficers";
import DistrictAdmin from "./components/admin/HomeDistrictAdmin/DistrictAdmin";
import NewDashboards from "./components/admin/dashboard/NewDashboards";
import Dashboard from "./components/admin/dashboard/Dashboard";

function App() {

    return (
        <NextUIProvider>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Login/>}/>
                    <Route path="/pranali" element={
                        <ProtectedRoute>
                            <Header/>
                        </ProtectedRoute>
                    }>
                        <Route exact path="" element={<NewDashboards/>}/>

                        <Route path="membership">
                            <Route exact path="" element={<Membership/>}/>
                            <Route path="edit" element={<EditMemberData/>}/>
                            <Route path="new" element={<MembershipStepper/>}/>
                        </Route>
                        <Route path="finances" element={<Finances/>}/>
                        <Route path="projects">
                            <Route exact path="" element={<Projects/>}/>
                            <Route path="new-report" element={<ReportProject/>}/>
                            <Route path="view-report" element={<ViewReportProject/>}/>
                            <Route path="edit-report" element={<ReportProject/>}/>
                        </Route>
                        <Route path="meetings">
                            <Route exact path="" element={<Meetings/>}/>
                            <Route path="new-report" element={<ReportMeetings/>}/>
                            <Route path="view-report" element={<ViewReportMeetingMinutes/>}/>
                            <Route path="edit-report" element={<ReportMeetings/>}/>
                        </Route>
                        <Route path="ambassadorials">
                            <Route exact path="" element={<Ambassadorial/>}/>
                            <Route path="new-report" element={<ReportAmbassadorial/>}/>
                            <Route path="view-report" element={<ViewReportAmbassadorial/>}/>
                            <Route path="edit-report" element={<ReportAmbassadorial/>}/>
                        </Route>
                        <Route path="clubs-3142">
                            <Route exact path="" element={<ClubListInUserDistrict/>}/>
                            <Route exact path="view-club-insights" element={<Dashboard/>}/>
                            <Route path="onboard-new" element={<OnBoardNewClubAndOfficers/>}/>
                        </Route>
                        <Route path="administration">
                            <Route exact path="" element={<DistrictAdmin/>}/>
                        </Route>
                        <Route path="aara">
                            <Route exact path="" element={<ViewNominations/>}/>
                            <Route path="new-nomination-projects" element={<NewNominationForProjects/>}/>
                            <Route path="new-nomination-individual" element={<NewNominationForIndividualAwards/>}/>
                            <Route path="view-nominations-district-admin" element={<ViewNominationsForDistrictAdmin/>}/>
                        </Route>
                        <Route path="council58-applications">
                            <Route exact path="" element={<ApplicationResponses/>}/>
                            <Route path="view" element={<IndividualApplications/>}/>
                        </Route>
                        <Route path="discounts" element={<EdgeCard/>}/>
                    </Route>
                    <Route path="/council58" element={<Questionnaire/>}/>
                    <Route path="/thankyou" element={<ThankYouPage/>}/>
                </Routes>
            </BrowserRouter>
        </NextUIProvider>
    );
}

export default App;
