"use client";

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {getHomeClubDataFetchStatus, selectHomeClubData} from "../../redux/slice/clubDataSlice";
import {useEffect} from "react";
import {
    Card,
    Chip,
    cn,
    Skeleton
} from "@nextui-org/react";
import {Icon} from "@iconify/react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {getClubDataById, getClubsList} from "../../../services/ClubServices";

export default function NewDashboards() {
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);
    const homeClubData = useSelector(selectHomeClubData);
    const fetchHomeClubDataStatus = useSelector(getHomeClubDataFetchStatus);

    const dataRow1 = [
        {
            title: "Current Active Members",
            value: homeClubData?.membershipCount?.active,
            change: homeClubData?.membershipCount?.percentageChangeType.toFixed(2),
            iconName: "solar:users-group-rounded-linear",
            bg: "secondary",
            bottomText: "as of last year"
        },
        {
            title: "Total Debit",
            value: homeClubData?.wallet?.debit,
            change: homeClubData?.wallet?.debitPercentage.toFixed(2),
            iconName: "mynaui:credit-card-minus",
            bg: "warning",
            bottomText: "as of last month"
        },
        {
            title: "Total Credit",
            value: homeClubData?.wallet?.credit,
            change: homeClubData?.wallet?.creditPercentage.toFixed(2),
            iconName: "material-symbols-light:finance-mode-rounded",
            bg: "success",
            bottomText: "as of last month"
        },
        {
            title: "Balance",
            value: homeClubData?.wallet?.balance,
            iconName: "solar:hand-money-linear",
            bg: "danger",
        },
    ];

    const dataCircleCharts = [
        {
            title: "Project Status",
            categories: ["Draft", "Early", "On-Time", "Late", "Deleted"],
            color: "warning",
            chartData: [
                {name: "Draft", value: homeClubData?.activities?.projectsStatus?.draft},
                {name: "Early", value: homeClubData?.activities?.projectsStatus?.early},
                {name: "On-Time", value: homeClubData?.activities?.projectsStatus?.onTime},
                {name: "Late", value: homeClubData?.activities?.projectsStatus?.late},
                {name: "Deleted", value: homeClubData?.activities?.projectsStatus?.deleted},
            ],
        },
        {
            title: "Meetings Status",
            categories: ["Draft", "Early", "On-Time", "Late", "Deleted"],
            color: "success",
            chartData: [
                {name: "Draft", value: homeClubData?.activities?.meetingsStatus?.draft},
                {name: "Early", value: homeClubData?.activities?.meetingsStatus?.early},
                {name: "On-Time", value: homeClubData?.activities?.meetingsStatus?.onTime},
                {name: "Late", value: homeClubData?.activities?.meetingsStatus?.late},
                {name: "Deleted", value: homeClubData?.activities?.meetingsStatus?.deleted},
            ],
        },
        {
            title: "Meetings Types",
            categories: ["GBM", "BOD", "Joint", "PIS", "OCV", "Letterhead Exchange", "Other"],
            color: "danger",
            chartData: [
                {name: "GBM", value: homeClubData?.activities?.meetingTypesCount?.gbm},
                {name: "BOD", value: homeClubData?.activities?.meetingTypesCount?.bod},
                {name: "Joint", value: homeClubData?.activities?.meetingTypesCount?.jointMeeting},
                {name: "PIS", value: homeClubData?.activities?.meetingTypesCount?.pis},
                {name: "OCV", value: homeClubData?.activities?.meetingTypesCount?.ocv},
                {name: "Other", value: homeClubData?.activities?.meetingTypesCount?.letterheadExchange},
                {name: "Letterhead Exchange", value: homeClubData?.activities?.meetingTypesCount?.anyOther},
            ],
        },
    ];

    useEffect(() => {
        getClubDataById(dispatch, user, null);
        getClubsList(dispatch, user);
    }, []);

    const formatTotal = (total) => {
        return total >= 1000 ? `${(total / 1000).toFixed(1)}K` : total;
    };

    const CircleChartCard = React.forwardRef(
        ({className, title, categories, color, chartData, ...props}, ref) => {
            return (
                <Card
                    ref={ref}
                    className={cn("min-h-[240px] border border-transparent dark:border-default-100", className)}
                    {...props}
                >
                    <div className="flex flex-col gap-y-2 p-4 pb-0">
                        <div className="flex items-center justify-between gap-x-2">
                            <dt>
                                <h3 className="text-small font-medium text-default-500">{title}</h3>
                            </dt>
                        </div>
                    </div>
                    <div className="flex h-full flex-wrap items-center justify-center gap-x-2 lg:flex-nowrap">
                        <ResponsiveContainer
                            className="[&_.recharts-surface]:outline-none"
                            height={200}
                            width="100%"
                        >
                            <PieChart accessibilityLayer margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                <Tooltip content={({label, payload}) => (
                                    <div
                                        className="flex h-8 min-w-[120px] items-center gap-x-2 rounded-medium bg-background px-1 text-tiny shadow-small">
                                        <span className="font-medium text-foreground">{label}</span>
                                        {payload?.map((p, index) => {
                                            const name = p.name;
                                            const value = p.value;
                                            const category = categories.find((c) => c.toLowerCase() === name) ?? name;

                                            return (
                                                <div key={`${index}-${name}`}
                                                     className="flex w-full items-center gap-x-2">
                                                    <div
                                                        className="h-2 w-2 flex-none rounded-full"
                                                        style={{
                                                            backgroundColor: `hsl(var(--nextui-${color}-${(index + 1) * 100}))`,
                                                        }}
                                                    />

                                                    <div
                                                        className="flex w-full items-center justify-between gap-x-2 pr-1 text-xs text-default-700">
                                                        <span className="text-default-500">{category}</span>
                                                        <span className="font-mono font-medium text-default-700">
                                                                {formatTotal(value)}
                                                            </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                         cursor={false}
                                />

                                <Pie
                                    animationDuration={1000}
                                    animationEasing="ease"
                                    data={chartData}
                                    dataKey="value"
                                    innerRadius="68%"
                                    nameKey="name"
                                    paddingAngle={-20}
                                    strokeWidth={0}
                                >
                                    {chartData.map((_, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={`hsl(var(--nextui-${color}-${(index + 1) * 100 + 100}))`}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>

                        <div
                            className="grid w-full grid-cols-3 lg:grid-cols-2 justify-center gap-4 p-4 text-tiny text-default-500 lg:p-0">
                            {chartData.map(({name, value}, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <span
                                        className="h-2 w-2 rounded-full"
                                        style={{
                                            backgroundColor: `hsl(var(--nextui-${color}-${((index + 1) * 100) + 100}))`,
                                        }}
                                    />
                                    <span className="capitalize">
                                        {name} : {value}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Card>
            );
        },
    );

    return (<>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-6">
            {/*<Card className="border border-transparent dark:border-default-100">
                <div className="flex p-4 justify-between">

                    <div className="flex flex-col gap-y-2">
                        <dt className="mx-4 text-small font-medium text-default-500">Current Active Members</dt>

                        <dd className="px-4 text-2xl font-semibold text-default-700">
                            <Skeleton className="rounded-lg" isLoaded={fetchHomeClubDataStatus === "complete"}>
                                {homeClubData?.membershipCount?.active}
                            </Skeleton>
                        </dd>
                    </div>

                    <div className="mt-1 flex h-12 w-12 items-center justify-center rounded-lg bg-secondary-50">
                        <Icon className="text-secondary" icon={"solar:users-group-rounded-linear"} width={30}/>
                    </div>
                </div>
                <div className="flex p-4 gap-2 bg-default-100">
                    <Skeleton className="rounded-lg" isLoaded={fetchHomeClubDataStatus === "complete"}>
                        <Chip
                            // className="absolute right-4 top-4"
                            classNames={{
                                content: "font-semibold text-[0.68rem]",
                            }}
                            color={
                                homeClubData?.membershipCount?.percentageChangeType > 0
                                    ? "success" : homeClubData?.membershipCount?.percentageChangeType === 0
                                        ? "warning" : "danger"
                            }
                            radius="sm"
                            size="sm"
                            startContent={
                                homeClubData?.membershipCount?.percentageChangeType > 0 ? (
                                    <Icon height={14} icon={"solar:course-up-linear"} width={14}/>
                                ) : homeClubData?.membershipCount?.percentageChangeType === 0 ? (
                                    <Icon height={12} icon={"solar:arrow-right-linear"} width={12}/>
                                ) : (
                                    <Icon height={12} icon={"solar:course-down-linear"} width={12}/>
                                )
                            }
                            variant="flat"
                        >
                            {homeClubData?.membershipCount?.percentageChangeType.toFixed(2)} %
                        </Chip>
                    </Skeleton>
                    <p>as of last year</p>
                </div>
            </Card>*/}
            {dataRow1.map(({title, value, change, bg, iconName, bottomText}, index) => (
                <Card key={index} className="border border-transparent dark:border-default-100">
                    <div className="flex p-4 justify-between">
                        <div className="flex flex-col gap-y-2">
                            <dt className="mx-4 text-small font-medium text-default-500">{title}</dt>

                            <dd className="px-4 text-2xl font-semibold text-default-700">
                                <Skeleton className="rounded-lg" isLoaded={fetchHomeClubDataStatus === "complete"}>
                                    {value}
                                </Skeleton>
                            </dd>
                        </div>

                        <div className={cn("mt-1 flex h-12 w-12 items-center justify-center rounded-lg", {
                            "bg-secondary-50": bg === "secondary",
                            "bg-success-50": bg === "success",
                            "bg-warning-50": bg === "warning",
                            "bg-danger-50": bg === "danger",
                        })}>
                            {bg === "secondary" ? (
                                <Icon className="text-secondary" icon={iconName} width={30}/>
                            ) : bg === "success" ? (
                                <Icon className="text-success" icon={iconName} width={30}/>
                            ) : bg === "warning" ? (
                                <Icon className="text-warning" icon={iconName} width={30}/>
                            ) : (
                                <Icon className="text-danger" icon={iconName} width={30}/>
                            )}
                        </div>
                    </div>

                    {(undefined !== bottomText && undefined !== change) &&
                        <div className="flex p-4 gap-2 bg-default-100">
                            <Skeleton className="rounded-lg" isLoaded={fetchHomeClubDataStatus === "complete"}>
                                <Chip
                                    // className="absolute right-4 top-4"
                                    classNames={{content: "font-semibold text-[0.68rem]"}}
                                    color={change > 0 ? "success" : change === 0 ? "warning" : "danger"}
                                    radius="sm"
                                    size="sm"
                                    startContent={
                                        change > 0 ? (
                                            <Icon height={14} icon={"solar:course-up-linear"} width={14}/>
                                        ) : change === 0 ? (
                                            <Icon height={12} icon={"solar:arrow-right-linear"} width={12}/>
                                        ) : (
                                            <Icon height={12} icon={"solar:course-down-linear"} width={12}/>
                                        )
                                    }
                                    variant="flat"
                                >
                                    {change.replace("-", "")} %
                                </Chip>
                            </Skeleton>
                            <p>{bottomText}</p>
                        </div>}
                </Card>
            ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {dataCircleCharts.map((item, index) => (
                <CircleChartCard key={index} {...item} />
            ))}
        </div>
    </>);
}